import { CurrencyBalance } from "../../components/l10n/Currency.tsx";

export function OngoingBalance({ value, bold }: { value: number; bold?: boolean }) {
    const colorClass = value > 0 ? "color-success" : value < 0 ? "color-error" : "color-text";

    return bold ? (
        <b className={colorClass}>
            <CurrencyBalance value={value} />
        </b>
    ) : (
        <span className={colorClass}>
            <CurrencyBalance value={value} />
        </span>
    );
}
