import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { WButton } from "../../components/button/WButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceCreateLinks } from "../api/resourceCreateLinks.ts";
import { AddPaymentMethodButton, TopUpNowButton } from "./ActionButtons.tsx";
import { billingStateAtom } from "./billingLogic.tsx";

export function PaymentTopItem() {
    const { defaultAccount, callToAction } = useSuspenseQueryAtom(billingStateAtom);

    // NB: We currently only show actions for the default account here (no explicit reason though)
    if (defaultAccount) {
        const { remainingCredit } = defaultAccount;

        return (
            <>
                {remainingCredit != null && (
                    <div className="whitespace-nowrap inline-flex items-center text-success pr-2">
                        <MaskIcon className="jp-card-icon size-0.75rem mr-1" />
                        Remaining credit <CurrencyBalance value={remainingCredit} />
                    </div>
                )}

                {(() => {
                    const { nextAction } = defaultAccount;
                    switch (nextAction) {
                        case "must_top_up":
                        case "top_up":
                            return <TopUpNowButton variant="ghost" size="bar" account={defaultAccount} />;
                        case "wait_for_verification":
                        case "add_payment_method":
                            return <AddPaymentMethodButton variant="ghost" size="bar" account={defaultAccount} />;
                        case "contact_support":
                            return null;
                        case null:
                            return null;
                        default:
                            exhaustiveSwitchCheck(nextAction);
                    }
                })()}
            </>
        );
    }

    if (callToAction.action === "create_account") {
        return (
            <WButton
                color="success"
                variant="ghost"
                size="bar"
                action={ResourceCreateLinks.billing_account}
                icon="jp-card-icon"
            >
                Create First Billing Account
            </WButton>
        );
    }

    return null;
}

export default PaymentTopItem;
